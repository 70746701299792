import React from 'react';
import './Welcome.css';
import Boxes from '../assets/images/Boxes.png';
import Preview from '../assets/images/Preview.png';

const Welcome = () => {
  return (
    <div className="welcome">
      <img src={Boxes} alt="Boxes" className='image'/>
      <div className='welcome-row'>
        <div className='welcome-column'>
             <h1>Welibri: The Social Media Platform for Book-lovers</h1>
              <p>Welibri is the ultimate social media app designed for readers, and we're launching very soon! Dive into engaging discussions, write and read reviews, start or join book clubs, and even annotate your favorite books. Welibri offers a vibrant community where readers can explore new worlds, share insights, and celebrate the joy of reading together.</p>
        </div>
      </div>
      <img src={Preview} alt="Preview" className='preview'/>
    </div>
);
};

export default Welcome;