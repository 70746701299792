import React from 'react';
import './Footer.css';
import Instagram from '../assets/icons/Instagram.png';
import TikTok from '../assets/icons/Tiktok.png';
import Pinterest from '../assets/icons/Pinterest.png';
import X from '../assets/icons/X.png';
import LinkedIn from '../assets/icons/Linkedin.png';

const Footer = () => {

  return (
    <footer className='footer-body'>
      <h2>You have reached the end of the page.</h2>
      <p>Follow Welibri on social media to get the best updates</p>
      <div className='footer-icons'> <a href="https://www.instagram.com/welibri/?igsh=b25nMzNveGR6dzc2&utm_source=qr" className='icon-row' target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt="Instagram" className="icon"/>
            <p>Instagram</p>
          </a>
          <a href="https://www.tiktok.com/@welibritiktok?_t=8kNY0dK7CJk&_r=1" className='icon-row' target="_blank" rel="noopener noreferrer">
            <img src={TikTok} alt="Tiktok" className="icon"/>
            <p>TikTok</p>
          </a>
          <a href="https://se.pinterest.com/welibri/?invite_code=8d7f32ede45b4559a0cd4e83396de6b3&sender=1087549147424407108" className='icon-row' target="_blank" rel="noopener noreferrer">
            <img src={Pinterest} alt="Pinterest" className="icon"/>
            <p>Pinterest</p>
          </a>
          <a href="https://x.com/welibri" className='icon-row' target="_blank" rel="noopener noreferrer">
            <img src={X} alt="X" className="icon"/>
            <p>X</p>
          </a>
          <a href="https://www.linkedin.com/company/93381750/admin/feed/following/" className='icon-row' target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="LinkedIn" className="icon"/>
          <p>LinkedIn</p>
          </a>
          </div>
      <p>2024 Welibri Website. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
