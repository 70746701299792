import React from 'react';
import './About.css';
import Pens from '../assets/icons/Pens.png';
import Star from '../assets/icons/Star.png';
import Key from '../assets/icons/Key.png';

const About = () => {
  return (
   <div className='about'>
    <h1>About us</h1>
    <p>Introducing a revolutionary social platform crafted exclusively for avid book enthusiasts! Immerse yourself in a vibrant community where your love for literature takes center stage.</p>
    <div className='about-boxes'>
        <div  className='box'>
            <img src={Pens} alt="Pens" className='image'/>
            <p>Unleash your creativity by sharing posts, book reviews, and even annotations of your current reads</p>
        </div>
        <div  className='box'>
            <img src={Star} alt="Star" className='image'/>
            <p>Display your favorite reads on your bookshelf in your profile and make lists to organize your books</p>
        </div>
        <div  className='box'>
            <img src={Key} alt="Key" className='image'/>
            <p>Open and join bookclubs where you can discuss books with fellow readers who share your love for literature</p>
        </div>
    </div>
   </div>
);
};

export default About;